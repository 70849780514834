import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SeoMeta from "../components/seo";
export const _frontmatter = {
  "title": "A Message from the Director"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SeoMeta title={props.pageContext.frontmatter.title} mdxType="SeoMeta" />
    <div className="container">
      <p>{`  Dear Preschool Family,`}</p>
      <p>{`  `}<strong parentName="p">{`Thank you for the opportunity to share our Early Childhood Center with you.`}</strong>{` We welcome you to our Valley School Family! We have been building the leaders of tomorrow since 1950 and serve students from age 2-5 years old. The school was established on the premise of providing a quality education at a reasonable price. Over 69 years later, Valley School maintains that same philosophy and has transformed into a 21st century school incorporating modern day technology, while maintaining the core values on which the school was built.`}</p>
      <p>{`  Our caring and compassionate teachers create a loving atmosphere that nurtures trusting relationships between students, parents, and teachers. As we bridge the gap between home and school, our students build a positive self-esteem, learn strong social-emotional skills, and ultimately become bright, independent thinkers. Our clean and safe setting allows children to explore, discover and learn through new technology, hand-on activities, and an age appropriate curriculum.`}</p>
      <p>{`  We have designed our program to balance structure and encourage free choice. Classroom routines introduce new ideas, encourage involvement, and build self-confidence, allowing each child to feel success without pressure. Recognizing that children grow in unpredictable stages, we treat each child as an individual, working to help them grow one step at a time. Activities are planned to help them emphasize the process, rather than the product, fostering a sense of accomplishment and pride.`}</p>
      <p>{`  We truly appreciate your interest in our ECC program and know that this is a very important decision for your family. Please know that we are here to help with this process to make the transition as seamless as possible. We would love to see you become part of our amazing Valley School Family and we are excited to work together with you to spark the wonder and joy of learning in your child.`}</p>
      <p>{`  Our office hours are 8:00am - 4:30pm. Please give us a call at 818-786-4720 to schedule your appointment. I would love to introduce you to our teachers, give you the  opportunity to explore our campus,  and answer any questions you may have. I am looking forward to meeting you and your family and showing you how we will help your child become a leader of tomorrow.`}</p>
      <p>{`  Sincerely,`}</p>
      <p>{`  Heidy Meneses`}<br parentName="p"></br>{`
`}{`Early Childhood Center Director`}<br parentName="p"></br>{`
`}{`Valley School`}<br parentName="p"></br>{`
`}{``}<a parentName="p" {...{
          "href": "mailto:hmeneses@valleyschool.com"
        }}>{`hmeneses@valleyschool.com`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      